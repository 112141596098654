<template>
  <div class="modal is-active is-clipped">
    <div class="modal-card" :style="height">
      <header class="modal-head" :style="cssVariable">
        <div class="modal-title">
          {{ $t("commentsTitle") }}
        </div>
        <div class="modal-card-close" @click="closeModal">
          <span style="margin: 0 2px">{{ $t("modalCloseText") }}</span>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Icons/close"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                d="M11.8766417,2.00829893 C11.4329138,2.0684958 11.0909091,2.44885362 11.0909091,2.90909091 L11.089697,11.0909091 L2.90909091,11.0909091 L2.7730982,11.1018835 C2.3164165,11.1835228 2,11.558745 2,12 L2.00829893,12.1233583 C2.0684958,12.5670862 2.44885362,12.9090909 2.90909091,12.9090909 L11.089697,12.9090909 L11.0909091,21.0909091 L11.1018835,21.2269018 C11.1835228,21.6835835 11.558745,22 12,22 L12.1233583,21.9917011 C12.5670862,21.9315042 12.9090909,21.5511464 12.9090909,21.0909091 L12.9078788,12.9090909 L21.0909091,12.9090909 L21.2269018,12.8981165 C21.6835835,12.8164772 22,12.441255 22,12 L21.9917011,11.8766417 C21.9315042,11.4329138 21.5511464,11.0909091 21.0909091,11.0909091 L12.9078788,11.0909091 L12.9090909,2.90909091 L12.8981165,2.7730982 C12.8164772,2.3164165 12.441255,2 12,2 L11.8766417,2.00829893 Z"
                id="Combined-Shape"
                fill="#D83C3E"
                transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
              ></path>
            </g>
          </svg>
        </div>
      </header>
      <section
        class="comment-modal-body"
        style="padding: 15px 0;"
        ref="commentView"
      >
        <div
          class="comment-control"
          :style="
            commentFocus
              ? { border: '0.5px solid #D0D0D0', borderRadius: '6px' }
              : {}
          "
        >
          <md-field
            style="margin: 0;padding: 0;min-height: 40px;display:flex;align-items: center"
          >
            <!-- <label :style="lang === 'ar'? {color: '#979797!important',right: 0, left: 'auto'}:{color: '#979797!important',left: '0',right: 'auto'}">{{$t('CommentPlaceholder')}}</label> -->
            <md-textarea
              :placeholder="$t('CommentPlaceholder')"
              @focus="focusHandler"
              ref="moreComment"
              rows="1"
              md-autogrow
              style="color:#101010;width: 100%"
            ></md-textarea>
          </md-field>
          <!-- <textarea class="textarea" id="standard-basic" :placeholder="$t('CommentPlaceholder')"  ref="comment" rows="1" :style="{borderColor: color}"></textarea> -->
          <div class="comment-button" v-if="commentFocus">
            <a type="button" @click="submit"
              ><span :style="{ color: bkColor, fontWeight: 700 }">{{
                $t("CommentSend")
              }}</span></a
            >
          </div>
        </div>
        <!-- Content ... -->
        <div class="comment-content" :style="{ height: screenHeight + 'px' }">
          <div v-if="comments.length !== 0">
            <div v-for="(comment, index) in comments" :key="index">
              <div class="comment-container">
                <div class="comment-info">
                  <span class="comment-body">
                    <router-link
                      :to="{
                        name: 'profile',
                        params: {
                          userId: comment.user_id._id,
                          id: competitionId
                        }
                      }"
                    >
                      <span class="comment-name"
                        >{{ comment.user_id.name }}
                      </span>
                    </router-link>
                    {{ comment.body }}
                  </span>
                </div>
                <div class="comment-actions">
                  <div class="comment-time">
                    <span v-if="lang === 'ar'">{{
                      getTime(comment.createdAt) | arabicize
                    }}</span>
                    <span v-else>{{ getTime(comment.createdAt) }}</span>
                  </div>
                  <div
                    class="comment-delete"
                    :style="{ color: color }"
                    v-if="currentUser === comment.user_id._id"
                  >
                    <svg
                      width="15px"
                      height="15px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      @click="commentDelete(comment._id, comment.video_id)"
                    >
                      <g
                        id="Icons/delete"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="trash-2"
                          transform="translate(3.000000, 2.000000)"
                          fill="#111111"
                        >
                          <path
                            d="M11,-0.75 C12.4625318,-0.75 13.6584043,0.391704595 13.7449812,1.83247767 L13.75,2 L13.75,3.25 L18,3.25 C18.4142136,3.25 18.75,3.58578644 18.75,4 C18.75,4.37969577 18.4678461,4.69349096 18.1017706,4.74315338 L18,4.75 L16.75,4.75 L16.75,18 C16.75,19.4625318 15.6082954,20.6584043 14.1675223,20.7449812 L14,20.75 L4,20.75 C2.53746816,20.75 1.34159572,19.6082954 1.25501879,18.1675223 L1.25,18 L1.25,4.75 L0,4.75 C-0.414213562,4.75 -0.75,4.41421356 -0.75,4 C-0.75,3.62030423 -0.467846118,3.30650904 -0.101770557,3.25684662 L0,3.25 L4.25,3.25 L4.25,2 C4.25,0.537468162 5.3917046,-0.658404279 6.83247767,-0.744981206 L7,-0.75 L11,-0.75 Z M15.25,4.75 L2.75,4.75 L2.75,18 C2.75,18.6472087 3.24187466,19.1795339 3.87219476,19.2435464 L4,19.25 L14,19.25 C14.6472087,19.25 15.1795339,18.7581253 15.2435464,18.1278052 L15.25,18 L15.25,4.75 Z M7,8.25 C7.37969577,8.25 7.69349096,8.53215388 7.74315338,8.89822944 L7.75,9 L7.75,15 C7.75,15.4142136 7.41421356,15.75 7,15.75 C6.62030423,15.75 6.30650904,15.4678461 6.25684662,15.1017706 L6.25,15 L6.25,9 C6.25,8.58578644 6.58578644,8.25 7,8.25 Z M11,8.25 C11.3796958,8.25 11.693491,8.53215388 11.7431534,8.89822944 L11.75,9 L11.75,15 C11.75,15.4142136 11.4142136,15.75 11,15.75 C10.6203042,15.75 10.306509,15.4678461 10.2568466,15.1017706 L10.25,15 L10.25,9 C10.25,8.58578644 10.5857864,8.25 11,8.25 Z M11,0.75 L7,0.75 C6.35279131,0.75 5.8204661,1.24187466 5.75645361,1.87219476 L5.75,2 L5.75,3.25 L12.25,3.25 L12.25,2 C12.25,1.35279131 11.7581253,0.820466101 11.1278052,0.756453609 L11,0.75 Z"
                            id="Combined-Shape"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <hr style="width: 100%;" />
            </div>
          </div>
          <div v-else>
            <div class="no-comment">
              <h3 class="no-comment-text">{{ $t("NoComment") }}</h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ar from "javascript-time-ago/locale/ar";
export default {
  name: "singleComment",
  data() {
    return {
      // currentId: true,
      commentFocus: false
    };
  },
  created() {
    this.$store.dispatch("fetchSingleComments");
  },
  methods: {
    focusHandler() {
      this.commentFocus = true;
    },
    commentDelete(commentId, videoId) {
      this.$store.dispatch("deleteCommentHandler", { commentId, videoId });
    },
    closeModal() {
      this.$store.dispatch("removeSingleComment");
      this.$router.push("/");
    },
    submit() {
      this.$refs.commentView.scrollTo(0, 0);
      this.$store.dispatch("newCommentHandler", {
        value: this.$refs.moreComment.localValue,
        id: this.videoId
      });
      this.$refs.moreComment.localValue = "";
    },
    getTime(time) {
      if (time === "") {
        return "";
      } else {
        var ts = new Date(time);
        if (this.lang === "ar") {
          TimeAgo.addLocale(ar);
          // Create relative date/time formatter.
          const timeAgo = new TimeAgo("ar-EG");
          return timeAgo.format(ts, "time");
        } else {
          TimeAgo.addLocale(en);
          // Create relative date/time formatter.
          const timeAgo = new TimeAgo("en-Us");
          return timeAgo.format(ts);
        }
      }
    }
  },
  computed: {
    bkColor() {
      return this.$store.getters.secondaryColor;
    },
    defaultImage() {
      return this.$store.getters.brandLogo;
    },
    competitionId() {
      return localStorage.getItem("competitionId");
    },
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor
      };
    },
    lang() {
      return this.$store.getters.lang;
    },
    height() {
      return {
        "--height": this.$store.getters.screenHeight + "px"
      };
    },
    screenHeight() {
      return this.$store.getters.screenHeight - 100;
    },
    comments() {
      return this.$store.getters.comments;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    currentUserAvatar() {
      return this.$store.getters.currentUserAvatar;
    },
    videoId() {
      return this.$store.getters.singleComment;
    }
  },
  filters: {
    arabicize: function(value) {
      const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return value.toString().replace(/[0-9]/g, function(w) {
        return arabicNums[+w];
      });
    }
  }
};
</script>

<style scoped>
/* custom modal style */
.comment-modal-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: var(--height) !important;
  height: var(--height);
  background: #fff;
}
.comment-modal-head {
  height: 50px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment-modal-title {
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
}
.comment-modal-close {
  color: #d83c3e;
  font-family: Cairo;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  text-align: right;
}
.comment-modal-body {
  background: #fff;
}
.comment-control {
  margin: 5px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  background-color: #f6f6f6;
  padding: 10px;
  position: relative;
}
.comment-input {
  border: none !important;
  box-shadow: none !important;
  margin: 0 10px;
}
.comment-content {
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
  /* height: 50%; */
  padding-top: 10px;
  width: 90%;
  max-width: 1024px;
  margin: 0 20px;
  padding-bottom: 50px;
}
.comment-name {
  color: #101010;
  font-family: Cairo;
  font-size: 13px;
  letter-spacing: 0.19px;
  line-height: 19px;
  font-weight: 900;
}
.comment-body {
  display: inline-block;
  color: #101010;
  font-family: Cairo;
  font-size: 13px;
  letter-spacing: 0.19px;
  line-height: 19px;
  word-wrap: break-word;
}
.comment-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment-time {
  color: #8e8e8e;
  font-family: Cairo;
  font-size: 12px;
  font-weight: 400;
}
.comment-delete {
  margin: 0 10px;
}
.no-comment {
  margin: 20px auto;
  text-align: center;
}
.no-comment-text {
  font-weight: bold;
  font-size: 20px;
}
</style>
