import axios from "../../axios-auth";
import router from "../../router";
const state = {
  userProfile: null,
  doIFollow: false,
  followers: 0,
  following: 0,
  pointRedeemed: false,
  wallet: null,
  loadingProfile: false
};

const mutations = {
  userProfile(state, data) {
    state.userProfile = data;
  },
  follow(state, data) {
    state.followers = data.followers;
    state.following = data.following;
  },
  doIFollow(state, data) {
    state.doIFollow = data;
  },
  pointRedeemed(state, data) {
    state.pointRedeemed = data;
  },
  wallet(state, data) {
    state.wallet = data;
  },
  loadingProfile(state, data) {
    state.loadingProfile = data;
  }
};

const actions = {
  fetchUserProfile({ commit, rootState }, data) {
    if (typeof rootState.token !== "undefined") {
      commit("loadingProfile", true);
      commit("loading", true);
      commit("resetLists");
      const params = {
        user_id: data.userId,
        competition_id: data.competitionId
      };
      const url = "/profile";
      axios
        .get(url, { params, headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          let user = { ...res.data.user };
          if (typeof user.avatar === "undefined") {
            user.avatar = "";
          }
          let follow = {
            following: res.data.following.length,
            followers: res.data.followers.length
          };
          console.log(follow);
          commit("userProfile", user);
          commit("follow", follow);
          commit("doIFollow", res.data.doIFollow);
          commit("fetchProfileVideos", res.data.videos);
          commit("wallet", res.data.wallet);
          commit("loadingProfile", false);
          commit("loading", false);
        })
        .catch(error => {
          console.log("ERROR fetchProfile: " + error);
          commit("loadingProfile", false);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  updateUserProfile({ commit, rootState }, data) {
    if (typeof rootState.token !== "undefined") {
      const url = "/profile";
      let formData = new FormData();
      if (
        typeof data.file !== "undefined" &&
        data.file !== "" &&
        data.file !== null
      ) {
        formData.append("file", data.file);
      }
      if (typeof data.name !== "undefined" && data.name !== null) {
        formData.append("name", data.name);
      }
      if (typeof data.phone !== "undefined" && data.phone !== null) {
        formData.append("phone", data.phone);
      }
      if (typeof data.membership_id !== "undefined") {
        formData.append("membership_id", data.membership_id);
      }
      for (var value of formData.values()) {
        console.log(value);
      }
      axios
        .put(url, formData, { headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          commit("userProfile", res.data.user);
          let navigator_info = window.navigator;
          let screen_info = window.screen;
          let uid = navigator_info.mimeTypes.length || "";
          uid += navigator_info.userAgent.replace(/\D+/g, "") || "";
          uid += navigator_info.plugins.length;
          uid += screen_info.height || "";
          uid += screen_info.width || "";
          uid += screen_info.pixelDepth || "";
          console.log(uid);
          let params = {
            foeihq: uid
          };
          const url = "/init";
          axios
            .get(url, { headers: { "X-Auth-Token": rootState.token }, params })
            .then(res => {
              commit("companyData", res.data.company);
              commit("userData", res.data.user);
              commit("competitonsData", res.data.competitions);
              commit("status", 200);
              commit("token", rootState.token);
              localStorage.setItem("token", rootState.token);
              commit("loading", false);
              let message = "";
              let lang = localStorage.getItem("lang");
              if (lang === "0") {
                message = "Profile updated successfully";
              } else {
                message = "تم التحديث بنجاح";
              }
              notie.alert({ type: "success", text: message });
            })
            .catch(error => {
              console.log("ERROR updateProfile: " + error);
              router.push("/error");
            });
        })
        .catch(error => {
          console.log("ERROR updateProfile: " + error);
          router.push("/error");
        });
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  },
  redeemUserPoint({ commit, rootState }, id) {
    if (typeof rootState.token !== "undefined") {
      if (!rootState.competitionLocked) {
        if (id !== "") {
          const url = "/videos/redeem";
          const body = {
            video_id: id
          };
          axios
            .post(url, body, { headers: { "X-Auth-Token": rootState.token } })
            .then(res => {
              if (res) {
                let message = "";
                let lang = localStorage.getItem("lang");
                if (lang === "0") {
                  message = "point redeemed successfully";
                } else {
                  message = "تم استبدال النقطة بنجاح";
                }
                notie.alert({ type: "success", text: message });
                commit("pointRedeemed", true);
              }
            })
            .catch(error => {
              console.log("ERROR redeem point: " + error);
              router.push("/error");
            });
        } else {
          let message = "";
          let lang = localStorage.getItem("lang");
          if (lang === "0") {
            message = "please select a video";
          } else {
            message = "الرجاء تحديد مقطع فيديو";
          }
          notie.alert({ type: "error", text: message });
        }
      } else {
        let message =
          "Error you can not redeem point right now please try again later or contact administartion";
        let lang = localStorage.getItem("lang");
        if (lang === "1") {
          message =
            "خطأ لا يمكنك استبدال النقطة الآن ، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بالإدارة";
        }
        notie.alert({ type: "error", text: message });
      }
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  },
  resetPointRedeemed({ commit }) {
    commit("pointRedeemed", false);
  }
};

const getters = {
  userProfile: state => {
    let userProfile = {
      ...state.userProfile,
      following: state.following,
      followers: state.followers,
      doIFollow: state.doIFollow,
      wallet: state.wallet
    };
    return userProfile;
  },
  pointRedeemed: state => {
    return state.pointRedeemed;
  },
  loadingProfile: state => {
    return state.loadingProfile;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
