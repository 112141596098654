import { render, staticRenderFns } from "./WinnersCard.vue?vue&type=template&id=27a1335e&scoped=true&"
import script from "./WinnersCard.vue?vue&type=script&lang=js&"
export * from "./WinnersCard.vue?vue&type=script&lang=js&"
import style0 from "./WinnersCard.vue?vue&type=style&index=0&id=27a1335e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a1335e",
  null
  
)

export default component.exports