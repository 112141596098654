<template>
  <div class="modal is-active is-clipped">
    <div class="modal-card" :style="height">
      <header class="modal-head">
        <div class="modal-title">
          <span>{{ $t("SearchTitle") }}</span>
        </div>
        <div class="modal-card-close" @click="closeModal">
          <span style="margin: 0 2px">{{ $t("modalCloseText") }}</span>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Icons/close"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                d="M11.8766417,2.00829893 C11.4329138,2.0684958 11.0909091,2.44885362 11.0909091,2.90909091 L11.089697,11.0909091 L2.90909091,11.0909091 L2.7730982,11.1018835 C2.3164165,11.1835228 2,11.558745 2,12 L2.00829893,12.1233583 C2.0684958,12.5670862 2.44885362,12.9090909 2.90909091,12.9090909 L11.089697,12.9090909 L11.0909091,21.0909091 L11.1018835,21.2269018 C11.1835228,21.6835835 11.558745,22 12,22 L12.1233583,21.9917011 C12.5670862,21.9315042 12.9090909,21.5511464 12.9090909,21.0909091 L12.9078788,12.9090909 L21.0909091,12.9090909 L21.2269018,12.8981165 C21.6835835,12.8164772 22,12.441255 22,12 L21.9917011,11.8766417 C21.9315042,11.4329138 21.5511464,11.0909091 21.0909091,11.0909091 L12.9078788,11.0909091 L12.9090909,2.90909091 L12.8981165,2.7730982 C12.8164772,2.3164165 12.441255,2 12,2 L11.8766417,2.00829893 Z"
                id="Combined-Shape"
                fill="#D83C3E"
                transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
              ></path>
            </g>
          </svg>
        </div>
      </header>
      <section class="modal-body" style="padding: 0;">
        <div class="search-control">
          <a role="button" class="search-icon" @click="searchHandler">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              :style="lang === 'ar' ? { transform: 'scaleX(-1)' } : {}"
            >
              <g
                id="Icons/search"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M11,2.25 C15.8324916,2.25 19.75,6.16750844 19.75,11 C19.75,13.0584575 19.0362432,15.0065673 17.758098,16.5583591 L17.693,16.634 L22.0303301,20.9696699 C22.2670115,21.2063513 22.3124352,21.561865 22.1666012,21.8441588 L22.1029482,21.9462117 L22.0303301,22.0303301 C21.7986594,22.2620008 21.4486763,22.312955 21.1520638,22.1613342 L21.0554051,22.1032612 L20.9696475,22.0303076 L16.634,17.693 L16.5583591,17.758098 C15.1789886,18.894227 13.4864779,19.5844196 11.6816733,19.7237695 L11.3420055,19.7434168 L11,19.75 C6.16750844,19.75 2.25,15.8324916 2.25,11 C2.25,6.16750844 6.16750844,2.25 11,2.25 Z M11,3.75 C6.99593556,3.75 3.75,6.99593556 3.75,11 C3.75,15.0040644 6.99593556,18.25 11,18.25 C15.0040644,18.25 18.25,15.0040644 18.25,11 C18.25,6.99593556 15.0040644,3.75 11,3.75 Z"
                  id="Combined-Shape"
                  fill="#B7B7B7"
                ></path>
              </g>
            </svg>
          </a>
          <input
            class="input search-input"
            v-model="searchName"
            type="text"
            v-on:keyup="searchHandlerKey"
            :placeholder="$t('SearchPlaceHolder')"
          />
        </div>
        <div
          class="modal-card-content"
          :style="{ maxHeight: screenHeight + 'px' }"
        >
          <div class="container" :style="{ height: screenHeight + 'px' }">
            <div class="row">
              <div class="col-12" style="margin-bottom: 20px">
                <div v-if="videosList.length !== 0">
                  <div :key="index" v-for="(video, index) in videosList">
                    <pull-to
                      v-if="index === 0"
                      :on-refresh="onRefresh"
                      style="width: 100%;"
                      :config="{
                        errorLabel: 'Error',
                        startLabel: 'Pull to refresh',
                        readyLabel: 'ready',
                        loadingLabel: 'loading',
                        pullDownHeight: 100
                      }"
                    >
                      <Card
                        :video="video"
                        :clickLike="clickLike"
                        :reportHandler="reportHandler"
                        :currentUser="currentUser"
                        :deleteHandler="deleteHandler"
                        :submitNewComment="submitNewComment"
                        :isRanking="false"
                      >
                        <Videos
                          :index="video._id"
                          :id="video.uri"
                          :videosListProps="videosList"
                        />
                      </Card>
                    </pull-to>
                    <div v-else>
                      <Card
                        :video="video"
                        :clickLike="clickLike"
                        :reportHandler="reportHandler"
                        :currentUser="currentUser"
                        :deleteHandler="deleteHandler"
                        :submitNewComment="submitNewComment"
                        :isRanking="false"
                      >
                        <Videos
                          :index="video._id"
                          :id="video.uri"
                          :videosListProps="videosList"
                        />
                      </Card>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <pull-to
                    :on-refresh="onRefresh"
                    style="width: 100%;min-height: 50px"
                    :config="{
                      errorLabel: 'Error',
                      startLabel: 'Pull to refresh',
                      readyLabel: 'ready',
                      loadingLabel: 'loading',
                      pullDownHeight: 100
                    }"
                  >
                  </pull-to>
                </div>
              </div>
            </div>
            <infinite-loading
              @infinite="loadMore"
              :identifier="change"
              :distance="1000"
            >
              <div slot="no-more" :style="{ color: color }">
                {{ $t("NoMoreMessage") }}
              </div>
              <div slot="no-results" :style="{ color: color }">
                {{ $t("NoResultsMessage") }}
              </div>
            </infinite-loading>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Card from "@/components/VideoCard/VideoCard.vue";
import Videos from "@/components/VideoCard/VideoComponent.vue";
import PullTo from "vue-pull-refresh";
export default {
  name: "search",
  data() {
    return {
      searchName: "",
      change: 0,
      page: 1
    };
  },
  components: {
    Card,
    Videos,
    PullTo
  },
  created() {
    this.$store.dispatch("resetSearch");
  },
  methods: {
    closeModal() {
      this.$router.back();
    },
    searchHandlerKey(e) {
      if (e.keyCode === 13) {
        this.searchHandler();
      }
    },
    searchHandler() {
      this.change++;
      this.$store.dispatch("fetchSearch", this.searchName.replace(/\s?$/, ""));
    },
    deleteHandler(id) {
      this.$store.dispatch("deleteVideoHandler", id);
    },
    onRefresh() {
      this.change++;
      this.$store.dispatch("fetchSearch", this.searchName);
    },
    clickLike(data) {
      this.$store.dispatch("likeButtonHandler", data);
    },
    reportHandler(id) {
      this.$store.dispatch("reportVideoHandler", id);
    },
    submitNewComment(value) {
      this.$store.dispatch("newCommentHandler", value);
    },
    loadMore($state) {
      this.$store.dispatch("infiniteLoadSearch", {
        search: this.searchName,
        loaderState: $state
      });
    }
  },
  computed: {
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor
      };
    },
    lang() {
      return this.$store.getters.lang;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    height() {
      return {
        "--height": this.$store.getters.screenHeight + "px"
      };
    },
    videosList() {
      return this.$store.getters.searchVideos;
    },
    screenHeight() {
      return this.$store.getters.screenHeight - 100;
    },
    color() {
      return this.$store.getters.primaryColor;
    }
  }
};
</script>

<style scoped>
.search-control {
  margin: 5px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  background-color: #f6f6f6;
  padding: 10px;
  position: relative;
}
.search-input {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  margin: 0 10px;
}

/* input {
  background-color: transparent;
  font-size: 1rem;
  color: var(--color)!important;
  border-color: var(--color)!important;
}
input::placeholder {
  color: #aaa!important;
} */
</style>
