<template>
  <div class="container">
    <div class="error-container">
      <div class="logo">
        <img alt="logo" v-lazysizes :src="logoImage" />
      </div>
      <div class="error-message">
        <span
          >Something went wrong !! <br />
          Please refresh your page</span
        >
      </div>
      <div class="error-message">
        <span>
          !! هناك خطأ ما<br />
          يرجى تحديث صفحتك</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "../assets/mainImage/Powered-by.png";
export default {
  data() {
    return {
      logoImage: Logo
    };
  }
};
</script>
<style scoped>
.container {
  box-shadow: none;
  background: url("../assets/mainImage/main.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.error-container {
  margin: 40% 0 0 0;
  text-align: center;
  padding: 20px;
}
.logo {
  width: 50%;
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
}
.logo img {
  width: 100%;
}
.error-message {
  text-align: center;
  color: #fff;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
  text-transform: capitalize;
}
</style>
