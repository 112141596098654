import axios from "axios";
import router from "./router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

instance.interceptors.request.use(config => {
  NProgress.start();
  return config;
});
instance.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  error => {
    NProgress.done();
    if (error.response.status === 469) {
      let message = "";
      let submitText;
      let cancelText;
      let lang = localStorage.getItem("lang");
      if (lang === "0") {
        message = "you need to be logged in or to be a member";
        (submitText = "go to login"), (cancelText = "back to home");
      } else {
        message = "تحتاج إلى تسجيل الدخول أو أن تكون عضوا";
        (submitText = "تسجيل الدخول"), (cancelText = "الصفحة الرئيسية");
      }
      notie.setOptions({
        overlayClickDismiss: false
      });
      notie.confirm(
        {
          text: message,
          submitText,
          cancelText
        },
        () => {
          let slug = localStorage.getItem("sl");
          if (slug === "pyramids") {
            const sub = window.location.host.split(".")[0];
            if (sub !== "dev") {
              window.open("https://pyramidsfc.shaga3app.com/", "_self");
            } else {
              window.open("https://dev.pyramidsfc.shaga3app.com/", "_self");
            }
          } else {
            router.push({ name: "login" });
          }
        },
        () => {
          let competetionId = localStorage.getItem("competitionId");
          if (competetionId) {
            router.push({ name: "home", params: { id: competetionId } });
          } else {
            router.push({ name: "competitions" });
          }
        }
      );
    } else if (error.response.status === 471) {
      let message = "You can not like not approved video";
      let lang = localStorage.getItem("lang");
      if (lang === "1") {
        message = "لا يمكنك اعجاب الفيديو غير الموافق عليه";
      }
      notie.alert({ type: "error", text: message });
    } else if (error.response.status === 420) {
      let competetionId = localStorage.getItem("competitionId");
      if (competetionId) {
        router.push({ name: "home", params: { id: competetionId } });
      } else {
        router.push({ name: "competitions" });
      }
      let lang = localStorage.getItem("lang");
      let message =
        "Sorry a big load now due to the current live competition & voting. Please try again at 3 pm while less traffic. Or login with your Degla membership ID";
      if (lang === "1") {
        message =
          "معلش ضغط المستخدمين دلوقتي كبير علشان التصويت و المنافسه الايڤ. ممكن تجرب الساعه ٣:٠٠ هيكون الضغط اقل. او الدخول برقم عضويه النادي .بالتوفيق";
      }
      notie.alert({ type: "error", text: message, time: 5, stay: true });
    } else if (error.response.status === 450) {
      let message = "Error!! the verification Code is incorrect";
      let lang = localStorage.getItem("lang");
      if (lang === "1") {
        message = "خطأ!! رمز التحقق غير صحيح";
      }
      notie.alert({ type: "error", text: message });
    } else if (error.response.status === 429) {
      let message = "Error!! too many trials. Please try again later";
      let lang = localStorage.getItem("lang");
      if (lang === "1") {
        message =
          "خطأ!! تم إجراء العديد من المحاولات. الرجاء معاودة المحاولة في وقت لاحق";
      }
      notie.alert({ type: "error", text: message });
    } else {
      let message = error.response.status + ": " + error.response.data.message;
      notie.alert({ type: "error", text: message });
    }
  }
);

export default instance;
