<template>
  <div class="col-12" style="padding: 0">
    <div v-if="featuredVideos.list.length !== 0">
      <div :key="index" v-for="(video, index) in featuredVideos.list">
        <pull-to
          v-if="index === 0"
          :on-refresh="onRefresh"
          style="width: 100%"
          :config="{
            errorLabel: $t('pullToRefreshError'),
            startLabel: $t('pullToRefreshLabel'),
            readyLabel: $t('pullToRefreshReady'),
            loadingLabel: $t('pullToRefreshLoading'),
            pullDownHeight: 100
          }"
        >
          <Card
            :video="video"
            :clickLike="clickLike"
            :index="index"
            :reportHandler="reportHandler"
            :deleteHandler="deleteHandler"
            :currentUser="currentUser"
            :submitNewComment="submitNewComment"
            :isRanking="false"
          >
            <FeaturedVideos :index="video._id" :id="video.uri" />
          </Card>
        </pull-to>
        <div v-else>
          <Card
            :video="video"
            :clickLike="clickLike"
            :index="index"
            :reportHandler="reportHandler"
            :deleteHandler="deleteHandler"
            :currentUser="currentUser"
            :submitNewComment="submitNewComment"
            :isRanking="false"
          >
            <FeaturedVideos :index="video._id" :id="video.uri" />
          </Card>
        </div>
      </div>
    </div>
    <div v-else>
      <pull-to
        :on-refresh="onRefresh"
        style="width: 100%; min-height: 50px"
        :config="{
          errorLabel: $t('pullToRefreshError'),
          startLabel: $t('pullToRefreshLabel'),
          readyLabel: $t('pullToRefreshReady'),
          loadingLabel: $t('pullToRefreshLoading'),
          pullDownHeight: 100
        }"
      >
      </pull-to>
    </div>
  </div>
</template>

<script>
import PullTo from "vue-pull-refresh";
export default {
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    featuredVideos() {
      return this.$store.getters.featuredVideos;
    }
  },
  methods: {
    clickLike(data) {
      this.$store.dispatch("likeButtonHandler", data);
    },
    deleteHandler(id) {
      this.$store.dispatch("deleteVideoHandler", id);
    },
    reportHandler(id) {
      this.$store.dispatch("reportVideoHandler", id);
    },
    submitNewComment(value) {
      this.$store.dispatch("newCommentHandler", value);
    },
    onRefresh() {
      // this.noMoreValue = false;
      this.$store.dispatch("fetchCountdown");
      this.$store.dispatch("fetchHome", { tab: "featured", page: 1 });
    }
  },
  components: {
    Card: () =>
      import(
        /* webpackPrefetch: true */ "@/components/VideoCard/VideoCard.vue"
      ),
    FeaturedVideos: () =>
      import(
        /* webpackPrefetch: true */ "@/components/VideoCard/VideoComponent.vue"
      ),
    PullTo
  }
};
</script>

<style scoped></style>
