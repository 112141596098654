export default {
  // competition selection
  selectCompetitionsTitle: "Choose Competition",
  competitionIsFinished: "completed",
  terms: "Terms & Conditions",
  faq: "FAQ",
  comingSoon: "Coming Soon",
  // header
  counterTitle: "THIS ROUND ENDS IN",
  counterFinished: "Competition Ended",
  counterProcessing: "Calculating the winner",
  counterWinner: "Congratulations &#127881;",
  counterDay: "D",
  counterHours: "H",
  counterMinutes: "M",
  counterSeconds: "S",
  mainMenuHome: "Competitions",
  mainMenuSearch: "Search",
  mainMenuLogin: "Login",
  mainMenuLanguage: "language",
  mainMenuPoints: "Redeem points",
  languageSelectionTitle: "language",
  // loading screen
  loadingText: "Loading Competition...",
  powerByText: "Powered By: ",
  // footer
  mainFooterTabHome: "Home",
  mainFooterTabFans: "Fans",
  mainFooterTabWinners: "Winners",
  mainFooterTabProfile: "Profile",
  // home
  homeMainTabNew: "New",
  homeMainTabMost: "Most liked",
  homeMainTabFeatured: "Featured",
  menuCardShare: "Share",
  menuCardReport: "Report",
  menuCardDelete: "Delete",
  RankTitle: "Rank",
  GiftTitle: "Gift",
  moreCommentsTitle1: "View all",
  moreCommentsTitle2: "comments",
  CommentPlaceholder: "Write a Comment...",
  CommentSend: "Send",
  NoComment: "No Comments found",
  commentsTitle: "Comments",
  pullToRefreshError: "Error!!",
  pullToRefreshLabel: "Pull to refresh",
  pullToRefreshReady: "Ready",
  pullToRefreshLoading: "Loading...",
  cancelButton: "Dismiss",
  copyUrlTitle: "Copy Url",
  sharingLink: "Sharing as a Link",
  sharingPost: "Sharing as a Post",
  // upload
  uploadText: "Upload & Compete Live",
  uploadTextDegla:
    "Press the camera button below to upload your video and be in Super Deglawy, you need to be logged in.",
  uploadVideoCaptionText: "Caption",
  uploadVideoCaptionPlaceholder: "Enter video caption",
  uploading: "UPLOADING",
  saving: "SAVING",
  finishUploadTitle: "Well Done !!",
  finishUpload:
    "Your Video is now in your profile &#128165; waiting for approval . We will review & if all ok you will join the competition right away..Prepare yourself & friends &#128170;",
  gobackToHome: "Back",
  finishUploadDegla:
    "Your Video is now in your profile &#128165; waiting for approval . We will review & if all ok you will join the competition right away..Prepare yourself & friends &#128170;",
  gobackToHomeDegla: "I agree",
  notValidVideo: "You have to upload a video to compete with !",
  bigCaption: "This is a long caption. please shorten it a bit !",
  smallCaption: "please enter a caption",
  // profile
  profileVideosTitle: "Videos",
  profileVideoLabel: "Videos",
  profileFollowingLabel: "Following",
  profileFollowersLabel: "Followers",
  profileEditProfilebutton: "Edit",
  redeemPointsButton: "Redeem Points",
  profileFollowingbutton: "Follow",
  profileUnfollowingbutton: "Unfollow",
  profileVideoNotApprove: "Video is waiting for approval",
  profileVideoWinner: "Video is waiting for approval",
  profileVideoNoRank: "Video is waiting for approval",
  noVideos: " Still no videos. Upload & compete live right away",
  // edit profile
  editProfileTitle: "Edit Profile",
  editProfileUploadButton: "Remove & Upload",
  editProfileName: "Full name",
  editProfileEmail: "Email",
  editProfileMobile: "Mobile",
  editProfileMembership: "Membership number",
  editProfileMembershipDegla: "Your membership number in Wadi Degla (optional)",
  editProfileUpdateButton: "Save",
  editProfileCancelButton: "Cancel",
  notValidImage: "You have to upload an image !",
  // redeem points
  redeemPointTitle: "Select a video ",
  noVideoToRedeem: "No Videos, Please add a video",
  redeemPointSaveButton: "Redeem",
  redeemPointCancelButton: "Cancel",
  // search
  SearchTitle: "Search",
  modalCloseText: "Close",
  SearchPlaceHolder: "Enter username to search",
  NoMoreMessage: "No more videos",
  NoResultsMessage: "No video found",
  // fans
  fansMainTabsFollowing: "Following",
  fansMainTabsFollowers: "Followers",
  noFans: "Nothing here!",
  noFollowing: "It looks like your are not following anyone. ",
  noFollower: "It looks like you don't have followers",
  // login
  loginTitle: "Login",
  verifyTitle: "Verify your email",
  verifySmsTitle: "Verify your mobile number",
  infoTitle: "Enter Your information",
  chooseLoginMethodTitle: "Please select one of the following method to login",
  smsLoginButton: "Sms Login",
  enterMobileSubtitle: "Enter your mobile number",
  loginMobileNumber: "Mobile",
  mobileRequiredError: "Mobile number is required",
  mobileInvalidError: "Mobile number is invalid",
  mobileConcurentError: "Please wait 10 minute and try again",
  loginButton: "Submit",
  enterCodeSubtitle: "Enter the verification code found in this email",
  enterSmsCodeSubtitle:
    "Enter the verification code found in a sms to your mobile number",
  codeLabel: "Code",
  codeRequiredError: "verification code is requried",
  codeNumericError: "verification code has to be numbers",
  codeError: "verification code not correct",
  codeButton: "verify",
  enterDataSubtitle: "Enter your information",
  loginInfoName: "Full name",
  nameRequiredError: "Full name is required",
  nameMaxLengthError: "Full Name has to be 25 charater long",
  loginInfoEmail: "Email",
  emailRequiredError: "Email is required",
  NotemailError: "Error not valid email",
  loginInfoMemberDegla: "Your membership number in Wadi Degla (optional)",
  loginInfoMember: "Your membership number (optional)",
  memberInvalid: "membership number is invalid",
  submitLoginData: "Submit",
  loginWithEmailSubtitle: "Please Enter your Email to login or register",
  loginEmail: "Email",

  // winners

  winnerName: "Winner",
  noWinnersTitle: "no winners!!",
  noWinnersSub: "no winners in this competition yet",
  noWinnersRound: "no winners in this round",
  // getmorePoints
  pointsMainTitle: "Get more Points",
  PointsTitle: "Choose one of the following: ",
  ScannerTitle: "QR code scanner",
  ScannerButton: "Scan",
  promoCodeLabel: "Promo code",
  redeemButton: "Redeem points",
  PromoCodeTitle: "Promo code"
};
