var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12",staticStyle:{"padding":"0"}},[(_vm.mostVideos.list.length !== 0)?_c('div',_vm._l((_vm.mostVideos.list),function(video,index){return _c('div',{key:index,attrs:{"id":video._id}},[(index === 0)?_c('pull-to',{staticStyle:{"width":"100%"},attrs:{"on-refresh":_vm.onRefresh,"config":{
          errorLabel: _vm.$t('pullToRefreshError'),
          startLabel: _vm.$t('pullToRefreshLabel'),
          readyLabel: _vm.$t('pullToRefreshReady'),
          loadingLabel: _vm.$t('pullToRefreshLoading'),
          pullDownHeight: 100
        }}},[_c('Card',{attrs:{"video":video,"clickLike":_vm.clickLike,"index":index,"reportHandler":_vm.reportHandler,"deleteHandler":_vm.deleteHandler,"currentUser":_vm.currentUser,"submitNewComment":_vm.submitNewComment,"isRanking":true}},[_c('TopVideos',{attrs:{"index":video._id,"id":video.uri}})],1)],1):_c('div',[_c('Card',{attrs:{"video":video,"clickLike":_vm.clickLike,"index":index,"reportHandler":_vm.reportHandler,"deleteHandler":_vm.deleteHandler,"currentUser":_vm.currentUser,"submitNewComment":_vm.submitNewComment,"isRanking":true}},[_c('TopVideos',{attrs:{"index":video._id,"id":video.uri}})],1)],1)],1)}),0):_c('div',[_c('pull-to',{staticStyle:{"width":"100%","min-height":"50px"},attrs:{"on-refresh":_vm.onRefresh,"config":{
        errorLabel: _vm.$t('pullToRefreshError'),
        startLabel: _vm.$t('pullToRefreshLabel'),
        readyLabel: _vm.$t('pullToRefreshReady'),
        loadingLabel: _vm.$t('pullToRefreshLoading'),
        pullDownHeight: 100
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }