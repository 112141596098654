import axios from "../../axios-auth";
import router from "../../router";
const state = {
  qrCode: false,
  promoCode: false,
  PointRedeemed: false
};

const mutations = {
  qrCode(state, data) {
    state.qrCode = data;
  },
  promoCode(state, data) {
    state.promoCode = data;
  },
  
};

const actions = {
  fetchMethods({ commit, rootState }, data) {
    if (typeof rootState.token !== "undefined") {
      const params = {
        competition_id: data.competitionId
      };
      // const url = '/profile'
      // axios.get(url, { params, headers: { 'X-Auth-Token': rootState.token } })
      // .then(res => {
      commit("qrCode", false);
      commit("promoCode", true);
      // })
      // .catch(error => {
      //   console.log('ERROR fetchProfile: ' + error)
      // })
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  promoCodeRedeem({ commit, rootState }, data) {
    if (typeof rootState.token !== "undefined") {
      const url = "/profile/promocode";
      console.log( rootState.selectedCompetition._id)
      const body = {
        code: data.code,
        competition_id: rootState.selectedCompetition._id
      };
      axios
        .post(url, body, { headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          if(res.data.points === -1){
            // promo code does not exist
            let message = "";
            let lang = localStorage.getItem("lang");
            if (lang === "0") {
              message = "ERROR code you enter does not exists";
            } else {
              message = "خطء الكود الذي أدخلته غير موجود";
            }
            notie.alert({ type: "error", text: message });
          }
          else if(res.data.points === -2){
            // promo code date is not vaild
            let message = "";
            let lang = localStorage.getItem("lang");
            if (lang === "0") {
              message = "ERROR code you enter either expired or not yet activated";
            } else {
              message = "خطء الكود الذي أدخلته غير مفاعل او منتهي";
            }
            notie.alert({ type: "error", text: message });
          }  
          else if(res.data.points === -3){
            let message = "";
            let lang = localStorage.getItem("lang");
            if (lang === "0") {
              message = "you already redeemed this code";
            } else {
              message = "خطء لقد استبدلت هذا الكود ";
            }
            notie.alert({ type: "error", text: message });
          }  
          else {
            let message = "";
            let lang = localStorage.getItem("lang");
            if (lang === "0") {
              message = res.data.points+" point redeemed successfully ";
            } else {
              const arabicNums = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']
              let points = res.data.points.toString().replace(/[0-9]/g, function (w) {
                return arabicNums[+w]
              })
              message = "تم استبدال  "+points+ " نقطة بنجاح";
            }
            notie.alert({ type: "success", text: message });
          }
        })
        .catch(error => {
          console.log("ERROR redeem point: " + error);
          router.push("/error");
        });
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  }
};

const getters = {
  promoCode: state => {
    return state.promoCode
  },
  qrCode: state => {
    return state.qrCode
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
