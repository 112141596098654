<template>
  <div style="text-align: center">
    <div style="margin: 10px">
      <qrcode-capture @decode="onDecode" :multiple="false" capture="user" @detect="onDetect" />
    </div>
    <button class="btn qr-button" :style="cssVariable">
      {{ $t("ScannerButton") }}
    </button>
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
export default {
  props: ["getCode"],
  data() {
    return {
      code: "",
      error: ""
    };
  },
  methods: {
     onDecode(decodedString) {
      console.log(decodedString)
    },
    async onDetect (promise) {
    try {
      const {
        imageData,    // raw image data of image/frame
        content,      // decoded String or null
        location      // QR code coordinates or null
      } = await promise
      console.log(location)
      console.log(content)
      console.log(imageData)
      if (content === null) {
         notie.alert({ type: "error", text: 'decode nothing' });
      } else {
         notie.alert({ type: "success", text: content});
      }
    } catch (error) {
      notie.alert({ type: "success", text: error.toString() });
    }
    }
  },
  computed: {
   
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor
      };
    }
  },
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
  }
};
</script>
<style scoped>
.qr-button {
  color: var(--color);
  background-color: var(--bkcolor);
}
</style>
