import axios from "../../axios-auth";
import router from "../../router";
const state = {
  singleVideo: null
};

const mutations = {
  singleVideo(state, data) {
    state.singleVideo = data;
  },
  // like
  likeHandlerSingle(state, data) {
    if (state.singleVideo) {
      if (state.singleVideo._id === data.videoId) {
        state.singleVideo.isLiked = true;
        state.singleVideo.likes++;
      }
    }
  },
  // comment
  lastCommentSingle(state, data) {
    if (state.singleVideo) {
      if (state.singleVideo._id === data.id) {
        state.singleVideo.lastComment = data.value;
        state.singleVideo.commentsCount++;
      }
    }
  },
  deleteLastcommentSingle(state, data) {
    if (state.singleVideo) {
      if (state.singleVideo._id === data.id) {
        state.singleVideo.lastComment = data.lastComment;
        state.singleVideo.commentsCount--;
      }
    }
  },
  // delete
  deleteVideoSingle(state, id) {
    if (state.singleVideo._id === id) {
      state.singleVideo = null;
      router.push("/");
    }
  },
  removeSingle(state) {
    state.singleVideo = null;
  },
  singleFollow(state, data) {
    if (state.singleVideo) {
      if (state.singleVideo.user_id._id === data.id) {
        state.singleVideo.user_id.isFollowed = data.followed;
        if (data.followed) {
          state.singleVideo.user_id.followerCount++;
        } else {
          state.singleVideo.user_id.followerCount--;
        }
      }
    }
  }
};

const actions = {
  fetchSingle({ commit, rootState }) {
    if (typeof rootState.token !== "undefined") {
      const videoId = rootState.singleVideo;
      if (videoId) {
        commit("loading", true);
        console.log("here");
        const url = "/home/single";
        const params = {
          video_id: videoId
        };
        axios
          .get(url, { params, headers: { "X-Auth-Token": rootState.token } })
          .then(res => {
            console.log(res);
            commit("singleVideo", res.data);
            commit("loading", false);
          })
          .catch(error => {
            console.log("ERROR fetchSearch: " + error);
            commit("loading", false);
          });
      } else {
        router.push({ name: "competitons" });
      }
    } else {
      commit("status", 400);
      router.push("/error");
    }
  }
};

const getters = {
  singleVideo: state => {
    return state.singleVideo;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
